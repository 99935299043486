import getters from "./getters"
import actions from "./actions"
import mutations from "./mutations"

import cacheLs from "../cacheLs"

const state = {
  posts: cacheLs.get_posts_ls(),
  postCommentCount: cacheLs.get_post_comment_count_ls(),
  postAddImage: cacheLs.get_post_add_image_ls()
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
