import cacheLs from "../cacheLs"

export default {
  setPosts (state, posts) {
    cacheLs.set_posts_list_ls(posts)
    state.posts = posts
  },
  setPostCommentCount (state, postCommnetCountObj) {
    cacheLs.set_posts_comment_count_ls(postCommnetCountObj)
    state.postCommentCount = postCommnetCountObj
  },
  setNewPost (state, newPost) {
    state.posts = Object.assign({}, { ...newPost, ...state.posts })
  },
  editPost (state, editedPost) {
    let editedPostId = editedPost.post_id
    state.posts[editedPostId] = { ...editedPost }
  },
  postLikeUnlike (state, likeObj) {
    let postId = likeObj.module_id
    let likeUnlikeData
    if (likeObj.like_status) {
      // when it is liked
      likeUnlikeData = { ...state.posts[postId], post_likedby_curr_user: 1, post_like_count: state.posts[postId].post_like_count + 1 }
    }
    else {
      // when module is unliked
      likeUnlikeData = { ...state.posts[postId], post_likedby_curr_user: 0, post_like_count: state.posts[postId].post_like_count - 1 }
    }
    state.posts[postId] = likeUnlikeData
  },
  postCommentShow (state, postId) {
    state.posts[postId] = Object.assign({}, { ...state.posts[postId], post_show_comment: true })
  },
  editPostCommentCount (state, { postId, updatationType }) {
    let commentCount = state.postCommentCount[postId]
    if (updatationType === "ADD") {
      state.postCommentCount[postId] = commentCount + 1
    }
    else if (updatationType === "DEL") {
      state.postCommentCount[postId] = commentCount - 1
    }
    else {
      return false
    }
  },
  setNewPostImage (state, newPostImage) {
    cacheLs.set_post_add_image_ls(newPostImage)
    state.postAddImage = newPostImage
  }
}
