import Vue from "vue"
import Vuex from "vuex"
import Setting from "./Setting/index"
import User from "./User/index"
import Post from "./Post/index"
import Paygw from "./Paygw/index"
import DateFilter from "./DateFilter/index"
import DeepLink from "./DeepLink/index"
import SourceTracker from "./SourceTracker/index"
import UserRole from "./UserRole/index"
import SubscriptionPlanAmount from "./SubscriptionPlanAmount/index"
import Class from "./Class/index"
import EventState from "./EventState/index"
import EventStatus from "./EventStatus/index"
import TPrepSubjectModuleList from "./TPrepSubjectModuleList/index"
import Intrests from "./Intrests/index"
import welcome from "./Welcome/index"
import QuizType from "./QuizType"
import Career from "./Career"
import Organisations from "./Organisations"
import Universities from "./Universities"
import Schools from "./Schools"
import UserInterest from "./UserInterest"
import GpathSession from "./GPathSession"
import Country from "./Country"
import MeshUrl from "./ MeshUrl"
import OpenMesh from "./OpenMesh"
import SaasOrgData from "./SaasOrgData"
import Notification from "./Notification"
import SPCMFireflies from "./SPCMFireflies"

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== "prod"

export default new Vuex.Store({
  modules: {
    Setting,
    User,
    Post,
    Paygw,
    DateFilter,
    DeepLink,
    SourceTracker,
    UserRole,
    SubscriptionPlanAmount,
    Class,
    EventState,
    EventStatus,
    TPrepSubjectModuleList,
    Intrests,
    welcome,
    QuizType,
    Career,
    Organisations,
    Universities,
    Schools,
    UserInterest,
    GpathSession,
    Country,
    MeshUrl,
    OpenMesh,
    SaasOrgData,
    Notification,
    SPCMFireflies
  },
  strict: debug
})
