import Vue from "vue"

Vue.component("iq-card", require("../components/socialvue/cards/iq-card").default)
Vue.component("Lottie", require("../components/socialvue/lottie/Lottie").default)
Vue.component("Slick", require("../components/socialvue/slider/Slick").default)

Vue.component("tab-nav", require("../components/socialvue/tab/tab-nav").default)
Vue.component("tab-nav-items", require("../components/socialvue/tab/tab-nav-items").default)
Vue.component("tab-content", require("../components/socialvue/tab/tab-content").default)
Vue.component("tab-content-item", require("../components/socialvue/tab/tab-content-item").default)
Vue.component("TimeLine", require("../components/socialvue/timeline/TimeLine").default)
