import Vue from "vue"
import VueRouter from "vue-router"

/* Import admin router file */
import AdminRouter from "./admin"

/* Import gide router file */
import GideRouter from "./gide"

Vue.use(VueRouter)

const routes = [
  ...AdminRouter,
  ...GideRouter
]

const router = new VueRouter({
  mode: "history",
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  base: process.env.VUE_APP_BASE_URL,
  routes
})

export default router
