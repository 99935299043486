/*eslint-disable */
import cacheLs from '../cacheLs'

export default {
  setAuthToken (state, token) {
    cacheLs.set_auth_token_ls(token);    
    state.token = token;
  },
  setUserProfile (state, userData) {
    cacheLs.CURRENT_USER_ID = userData.user_id
    if(cacheLs.CURRENT_USER_ID){
      cacheLs.set_current_user_id_ls(cacheLs.CURRENT_USER_ID)
    }
    else{
      cacheLs.CURRENT_USER_ID = cacheLs.get_current_user_id_ls()
    }
    cacheLs.set_user_data_ls(userData)
    state.userData = userData
  },
  setFcmPushToken (state, fcm_push_token) {
    cacheLs.setFcmPushToken_ls(fcm_push_token)
    state.fcm_push_token = fcm_push_token
  },
  setSelectedUserInterest (state, selectedUserInterests) {
    cacheLs.set_selected_user_interests_ls(selectedUserInterests)
    state.selectedUserInterests = selectedUserInterests
  },
  setAskedForUserInterest (state, askedForUserInterests) {
    cacheLs.set_asked_for_user_interests_ls(askedForUserInterests)
    state.askedForUserInterests = askedForUserInterests
  },
  setUserLogout(state){
    cacheLs.destroy_data_ls();
    state.userData = null
    state.token = null
    state.userId = null
  },
   refreshUserInterests(state) {
    state.refreshUserInterests = Math.random()
  }
}
