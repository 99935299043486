export default {
  notification (state) {
    if (typeof state.notification == "string") {
      return JSON.parse(state.notification)
    }
    else {
      return state.notification
    }
  }
}
