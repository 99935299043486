import cacheLs from "../cacheLs"

export default {
  addSelectedPlanCommit (state, data) {
    cacheLs.set_selectedPlan(data)
    state.selectedPlan = data
  },
  addMyPlan (state, data) {
    cacheLs.set_MyPlan(data)
    state.myPlan = data
  },
  addInvoicePlanCommit (state, data) {
    cacheLs.set_InvoicePlan(data)
    state.invoicePlan = data
  },
  addSelectedModuleForPlanCommit (state, data) {
    cacheLs.set_SelectedModuleForPlan(data)
    state.selectedModuleForPlan = data
  }
}
