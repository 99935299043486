/*eslint-disable */
/**
 * @copyright    Copyright © 2019-2025 MiniVentureLab
 * @author       Shubham Shukla  <shukla@gide.ai>
 * @package      Gide
 * @license      Proprietary
 * @description  AES encrypt and dcrypt functionality
 * 
 * 
 */

var aes256 = require('aes256')

let encryptAndDecrypt = {
  /**
   * aes_encrypt
   */
  aes_encrypt(key, data) {
    key = key+process.env.VUE_APP_AES_SALT;
    let cipher = aes256.createCipher(key);
    return cipher.encrypt(JSON.stringify(data));
  },

  /*
   * aes_decrypt
   */
  aes_decrypt(key, data) {
    key = key+process.env.VUE_APP_AES_SALT;
    let cipher = aes256.createCipher(key);
    return cipher.decrypt(data);
  },
}

export default encryptAndDecrypt;