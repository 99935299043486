/*eslint-disable */

export default {
  getToken(state) {
    return state.token
  },
  userData(state) {
    return state.userData
  },
  userId(state) {
    return state.userData.user_id
  },
  getFcmPushToken(state) {
    return state.fcm_push_token
  },
  getSelectedUserInterests(state) {
    return state.selectedUserInterests
  },
  getAskedForUserInterests(state) {
    return state.askedForUserInterests
  },
}
