export default {
  setPostsAction (context, payload) {
    return new Promise((resolve, reject) => {
      context.commit("setPosts", payload)
      resolve()
    })
  },
  setPostCommentCountAction (context, payload) {
    return new Promise((resolve, reject) => {
      context.commit("setPostCommentCount", payload)
      resolve()
    })
  },
  setNewPostAction (context, payload) {
    return new Promise((resolve, reject) => {
      context.commit("setNewPost", payload)
      resolve()
    })
  },
  editPostAction (context, payload) {
    return new Promise((resolve, reject) => {
      context.commit("editPost", payload)
      resolve()
    })
  },
  postLikeUnlikeAction (context, payload) {
    return new Promise((resolve, reject) => {
      context.commit("postLikeUnlike", payload)
      resolve()
    })
  },
  postCommentShowAction (context, payload) {
    return new Promise((resolve, reject) => {
      context.commit("postCommentShow", payload)
      resolve()
    })
  },
  editPostCommentCountAction (context, payload) {
    return new Promise((resolve, reject) => {
      context.commit("editPostCommentCount", payload)
      resolve()
    })
  },
  setNewPostImageAction (context, payload) {
    return new Promise((resolve, reject) => {
      context.commit("setNewPostImage", payload)
      resolve()
    })
  },
  setSocketUserRoleUpdated (context) {
    return new Promise((resolve, reject) => {
      context.commit("setSocketUserRoleUpdated", Math.random)
      resolve()
    })
  }
}
