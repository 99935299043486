/*eslint-disable */
import request from '../../Utils/curl'
import Utility from "../../Utils/utility";

let User = {
  /**
   * userVerify
   */
  async userVerify (context, userObj) {
    try{
      let post_data = new FormData();
      post_data.append("user_email", userObj.user_email);
      return await request.curl(context, "user_verify", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at userVerify() and Exception:',err ,"helloo")
    }
  },
  /**
   * userLogin
   */
  async userLogin (context, userObj, api) {
    let post_data = new FormData();
    post_data.append("user_email", userObj.user_email);
    
    if (api === 'user_login_google') {
      post_data.append("token", userObj.token);
      post_data.append("user_image", userObj.user_image);
      post_data.append("user_name", userObj.user_name);
    }
    else if(api === 'user_login_email') {
      post_data.append("user_password", userObj.user_password);
    }
    else if(api == 'user_login_apple'){

      if(userObj.user_name){
        post_data.append("user_name", userObj.user_name);
      }

      post_data.append("token", userObj.token);
      post_data.append("code", userObj.code);
      post_data.append("redirectURI", btoa(userObj.redirectURI));
    }

    if(typeof userObj.keep_me_logged_in === 'boolean') {
      post_data.append("keep_me_logged_in", userObj.keep_me_logged_in);
    }
    return await request.curl(context, api, post_data)
      .then(async response => {
        return response
      });
  },
  /**
   * userRegister
   */
  async userRegister (context, userObj) {
    try{
      let post_data = new FormData();
      post_data.append("user_id", null);
      post_data.append("user_email", userObj.user_email);
      return await request.curl(context, "user_register", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at userRegister() and Exception:',err.message)
    }
  },
  /**
   * userActivate
   */
  async userActivate (context, userObj) {
    try{
      let post_data = new FormData();
      post_data.append("user_id", userObj.user_id);
      post_data.append("u", userObj.user_email);
      post_data.append("c", userObj.activation_code);
      return await request.curl(context, "user_activate", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at userActivate() and Exception:',err.message)
    }
  },
  /**
   * userProfileComplete
   */
  async userProfileComplete (context, userObj) {
    try{
      let post_data = new FormData();
      post_data.append("user_id", userObj.user_id);
      post_data.append("user_name", userObj.user_name);
      post_data.append("user_email", userObj.user_email);
      post_data.append("user_mobile", userObj.user_mobile);      
      post_data.append("user_role", userObj.user_role);

      if (userObj.user_password) {
        post_data.append("user_password", userObj.user_password);
      }

      if (userObj.student_organisation_name) {
        post_data.append("student_organisation_name", userObj.student_organisation_name);
      }

      if (userObj.user_city) {
        post_data.append("user_city", userObj.user_city);
      }

      if (userObj.user_country) {
        //country id in the country table
        post_data.append("user_country", userObj.user_country);
      }

      if (userObj.user_country_name) {
        post_data.append("user_country_name", userObj.user_country_name);
      }

      if (userObj.student_organisation) {
        post_data.append("student_organisation", userObj.student_organisation);
      }

      if (userObj.student_class) {
        post_data.append("student_class", userObj.student_class);
      }

      if (userObj.student_board) {
        post_data.append("student_board", userObj.student_board);
      }

      if (userObj.educator_organisation) {
        post_data.append("educator_organisation", userObj.educator_organisation);
      }

      if (userObj.educator_role) {
        post_data.append("educator_role", userObj.educator_role);
      }

      if (userObj.org_id) {
        post_data.append("org_id", userObj.org_id);
      }

      if (userObj.organisation_role) {
        post_data.append("organisation_role", userObj.organisation_role);
      }

      if(userObj.institute_name) {
        post_data.append('institute_name', userObj.institute_name)
      }

      if(userObj.params) {
        post_data.append('params', userObj.params)
      }

      if(userObj.module_type) {
        post_data.append('module_type', userObj.module_type)
      }

      if(userObj.module_obj_id) {
        post_data.append('module_obj_id', userObj.module_obj_id)
      }

      if(userObj.source) {
        post_data.append('source', userObj.source)
      }

      if (userObj.user_first_time_login || userObj.user_first_time_login == 0) {
        post_data.append('user_first_time_login', userObj.user_first_time_login)
      }

      post_data.append("user_tnc", userObj.tnc);
      return await request.curl(context, "user_quick_profile_add", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at userProfileComplete() and Exception:',err.message)
    }
  },
  /**
   * Edit User Profile
   */
  async userEdit (context, userObj){
    try {
      let post_data = new FormData();
      for (let key in userObj) {       
        if (userObj[key] && typeof userObj[key] != 'number') {
          // Avoid null except number
          post_data.append(key, userObj[key]);
        }
        else if (userObj[key] || typeof userObj[key] == 'number') {
          post_data.append(key, userObj[key]);
        }
      }
      return await request.curl(context,'user_edit',post_data)
        .then(async response => {
          return response
        } )
    }
    catch (error) {
      console.error('Exception occurred at userEdit() and Exception:',error.message)
    }
  },

  /**
   * Edit User Profile
   */
   async userEditConsole (context, userObj){
    try {
      let post_data = new FormData();
      for (let key in userObj) {
        post_data.append(key, userObj[key]);
      }
      return await request.curl(context,'user_edit_console',post_data)
        .then(async response => {
          return response
        } )
    }
    catch (error) {
      console.error('Exception occurred at userEditConsole() and Exception:',error.message)
    }
  },

  /**
   * User change Password
   */
  async userPasswordChange (context, userObj){
    try {

      let post_data = new FormData();
      for (let key in userObj) {
        post_data.append(key, userObj[key]);
      }

      return await request.curl(context,'user_password_change',post_data)
        .then(async response => {
          return response
        } )
    }
    catch (error) {
      console.error('Exception occurred at userPasswordChange() and Exception:',error.message)
    }
  },
   /**
   * User Send OTP
   */
  async SendOtp (context, userObj){
    try {
      let post_data = new FormData();
      for (let key in userObj) {
        post_data.append(key, userObj[key]);
      }
      return await request.curl(context, 'user_send_otp',post_data)
        .then(async response => {
          return response
        } )
    }
    catch (error) {
      console.error('Exception occurred at SendOtp() and Exception:',error.message)
    }
  },
     /**
   * User ReSend OTP
   */
  async ReSendOtp (context, userObj){
    try {
      let post_data = new FormData();
      for (let key in userObj) {
        post_data.append(key, userObj[key]);
      }
      return await request.curl(context, 'user_resend_otp',post_data)
        .then(async response => {
          return response
        } )
    }
    catch (error) {
      console.error('Exception occurred at SendOtp() and Exception:',error.message)
    }
  },
  /**
   * User Verify OTP
  */
  async Verify_OTP (context,userObj){
    try {
      let post_data = new FormData();
      for (let key in userObj) {
        post_data.append(key, userObj[key]);
      }
      return await request.curl(context,'user_otp_verify',post_data)
        .then(async response => {
          return response
        } )
    }
    catch (error) {
      console.error('Exception occurred at Verify_OTP() and Exception:',error.message)
    }
  },
  /**
   * User Forgot Password use to send reset link
  */
  async userForgotPassword (context, user_email) {
    try {
      let post_data = new FormData();
        post_data.append('user_email', user_email);
      return await request.curl(context,'user_forgot_password',post_data)
        .then(async response => {
          return response
        } )
    }
    catch (error) {
      console.error('Exception occurred at userForgotPassword() and Exception:',error.message)
    }
  },
  /*
  * Password change using token
  */
  async userResetPassword (context, token, password, otp, email) {
    try {
      let post_data = new FormData();
        post_data.append('token', token);
        post_data.append('password', password);
        post_data.append('otp', otp)
        post_data.append('email',email)
      return await request.curl(context,'user_reset_password',post_data)
        .then(async response => {
          return response
        } )
    }
    catch (error) {
      console.error('Exception occurred at userResetPassword() and Exception:',error.message)
    }
  },
  /**
   * userProfileEdit
   */
  async userProfileEdit (context, userObj) {
    try{
      let post_data = new FormData();
      post_data.append("user_interest", JSON.stringify(userObj.user_interest));
      return await request.curl(context, "user_interests_edit", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at userRegister() and Exception:',err.message)
    }
  },
  /**
   * userInterestList
   */
  async userInterestList (context, curr_user_id, interest_type='INTRST') {
    try{
      let post_data = new FormData();
      post_data.append('curr_user_id', curr_user_id)
      post_data.append('interest_type', interest_type)
      return await request.curl(context, "user_interest_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at userRegister() and Exception:',err.message)
    }
  },
  /**
   * userList
   */
  async userList (context, whereFilter = null) {
    Utility.showLoader = true;
    Utility.loadingMsg = "Please wait....";   
    try{
      let post_data = new FormData();
      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }

      return await request.curl(context, "user_list", post_data)
      .then(async response => {
        return response
      });
    }
    catch(err){
      console.error('Exception occurred at userList() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },
  /**
   * userListSchool
   */
  async userListSchool (context, whereFilter = null) {
    Utility.showLoader = true;
    Utility.loadingMsg = "Please wait....";   
    try{
      let post_data = new FormData();
      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }

      return await request.curl(context, "user_list_school", post_data)
      .then(async response => {
        return response
      });
    }
    catch(err){
      console.error('Exception occurred at userListSchool() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },
  /**
   * userListSearch
   */
  async userListSearch (context, userObj) {   
    try{
      let post_data = new FormData();
      if (userObj) {
        for (const key in userObj) {
          if (userObj[key] || typeof userObj[key] == "number") {
            post_data.append(key, userObj[key])   
          }
        }
      }

      return await request.curl(context, "user_list_search", post_data)
      .then(async response => {
        return response
      });
    }
    catch(err){
      console.error('Exception occurred at userListSearch() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },
  /**
   *  userView
   */
  async userView (context, currentUserId, userId){
    try{
      let post_data = new FormData();
      post_data.append('user_id', userId)
      post_data.append('match_with', 'user_id')
      if (currentUserId) {
        post_data.append('curr_user_id', currentUserId)
      }
      return await request.curl(context, "user_view", post_data)
        .then(async response => {
          return response
        });
    }
    catch(err){
      console.error('Exception occurred at userView() and Exception',err.message)
    }
  },

  /**
   *  user view public
   */
  async userViewPublic (context, currentUserId, userId, pp=1){
    try{
      let post_data = new FormData();
      post_data.append('user_id', userId)
      post_data.append('match_with', 'user_id')
      post_data.append('pp', pp)
      if (currentUserId) {
        post_data.append('curr_user_id', currentUserId)
      }
      return await request.curl(context, "user_view_public", post_data)
        .then(async response => {
          return response
        });
    }
    catch(err){
      console.error('Exception occurred at user_view_public() and Exception',err.message)
    }
  },

  /**
  *  userViewWithRoles
  */
  async userViewWithRoles (context, userObj){
    try{
      let post_data = new FormData();
      post_data.append('curr_user_id', userObj.curr_user_id)
      return await request.curl(context, "user_view_with_roles", post_data)
        .then(async response => {
          return response
        });
    }
    catch(err){
      console.error('Exception occurred at userView() and Exception',err.message)
    }
  },
  /**
   * userBlock
   */
   async userBlock (context, userObj) {
    try{
    let post_data = new FormData();
    
    for (let key in userObj) {
      post_data.append(key, userObj[key]);
    }

    return await request.curl(context, "user_block", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at userDelete() and Exception:',err.message)
    }
  },
  /**
   * userDelete
   */
   async userDelete (context, userId) {
    try{
    let post_data = new FormData();
    
    post_data.append('user_id', userId);

    return await request.curl(context, "user_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at userDelete() and Exception:',err.message)
    }
  },
  /**
   * userRestoreAccount
   */
  async userRestoreAccount (context, userId, deleted) {
    try{
    let post_data = new FormData();
    
    post_data.append('user_id', userId);
    post_data.append('deleted', deleted);

    return await request.curl(context, "user_account_restore", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at userRestoreAccount() and Exception:',err.message)
    }
  },
  /**
   * userInviteAdd
   */
  async userInviteAdd (context, userInviteObj) {
    try{
      let post_data = new FormData();

      for (let key in userInviteObj) {
        post_data.append(key, userInviteObj[key]);
      }

      return await request.curl(context, "userInvite_add", post_data)
        .then(async response => {
          return response;
        });
    }
    catch(err){
      console.error('Exception occurred at userInviteAdd() and Exception:',err.message)
    }
  },
  /**
   * userInviteEdit
   */
  async userInviteEdit (context, userInviteObj) {
    try{
      let post_data = new FormData();

      for (let key in userInviteObj) {
        post_data.append(key, userInviteObj[key]);
      }

      return await request.curl(context, "userInvite_edit", post_data)
        .then(async response => {
          return response;
        });
    }
    catch(err){
      console.error('Exception occurred at userInviteEdit() and Exception:',err.message)
    }
  },
  /**
   * userInviteList
   */
  async userInviteList (context, moduleObjId, filter  = {}) {
    try{
      let post_data = new FormData();
      post_data.append('module_obj_id', moduleObjId);
      post_data.append('filter', JSON.stringify(filter));
      return await request.curl(context, "userInvite_list", post_data)
        .then(async response => {
          return response;
        });
    }
    catch(err){
      console.error('Exception occurred at userInviteList() and Exception:',err.message)
    }
  },
  /**
   * userActivationEmail
   */
  async userActivationEmail (context, userEmail) {
    try{
      let post_data = new FormData();
      post_data.append('user_email', userEmail);
      return await request.curl(context, "user_activation_email", post_data)
        .then(async response => {
          return response;
        });
    }
    catch(err){
      console.error('Exception occurred at userActivationEmail() and Exception:',err.message)
    }
  },
  /**
   * User Profile Add
  */
  async useProfileAdd (context, userObj){
    try {
      Utility.showLoader = true
      Utility.loadingMsg = "Please wait...."
      let post_data = new FormData();
      let s3Attachments = []
      
      for (let key in userObj) {
        if( key === 'attachments' && userObj.attachments && userObj.attachments.length ){
          for (const attachment of userObj.attachments) {
            if (attachment.url && attachment.url.includes('amazonaws.com')) {
              // if file is already uploaded
              s3Attachments.push(attachment);
              continue
            }
            post_data.append(key, attachment);
          }
        }
        else{
          post_data.append(key, userObj[key]);
        }
      }

      post_data.append('s3Attachments', JSON.stringify(s3Attachments))

      return await request.curl(context,'user_profile_add_edit',post_data)
        .then(async response => {
          return response
      })
    }
    catch (error) {
      console.error('Exception occurred at userEdit() and Exception:',error.message)
    } finally {
      Utility.showLoader = false
      Utility.loadingMsg = ""
    }
  },
  /**
   *  userProfileView
  */
  async userProfileView (context, currentUserId){
    try{
      let post_data = new FormData();
      post_data.append('curr_user_id', currentUserId)
      return await request.curl(context, "user_profile_view", post_data)
        .then(async response => {
          return response
        });
    }
    catch(err){
      console.error('Exception occurred at userProfileView() and Exception',err.message)
    }
  },
  /**
  *  userPublicViewProfile
  */
  async userPublicViewProfile (context, currentUserId, userId, pp=1){
    try{
      let post_data = new FormData();
      post_data.append('curr_user_id', currentUserId)
      post_data.append('user_id', userId)
      post_data.append('pp', pp)
      return await request.curl(context, "user_profile_view_public", post_data)
        .then(async response => {
          return response
        });
    }
    catch(err){
      console.error('Exception occurred at userPublicViewProfile() and Exception',err.message)
    }
  },
  /**
   * userBillingSwap
   */
  async UserBillDetailsSwap (context, userBillSwapDetails) {
    try{
      let post_data = new FormData();
      for (let key in userBillSwapDetails) {
        if ((userBillSwapDetails[key] && (typeof userBillSwapDetails[key] !== 'number') || typeof userBillSwapDetails[key] === 'number')) {
          post_data.append(key, userBillSwapDetails[key]);
        }
      }
      return await request.curl(context, "User_billing_swap", post_data)
        .then(async response => {
          return response
        });
    }
    catch(err){
      console.error('Exception occurred at userProfileView() and Exception',err.message)
    }
  },
  /**
   * userCounsellorList
  */
  async userCounsellorList (context, whereFilter = null) {
    Utility.showLoader = true;
    Utility.loadingMsg = "Please wait....";   
    try{
      let post_data = new FormData();
      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }

      return await request.curl(context, "user_counsellor_list", post_data)
      .then(async response => {
        return response
      });
    }
    catch(err){
      console.error('Exception occurred at userList() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  }
}

export {
  User
}
