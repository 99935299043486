export default {
  addSelectedPlanAction (context, payload) {
    return new Promise((resolve, reject) => {
      context.commit("addSelectedPlanCommit", payload)
      resolve()
    })
  },
  addMyPlan (context, payload) {
    return new Promise((resolve, reject) => {
      context.commit("addMyPlan", payload)
      resolve()
    })
  },
  addInvoicePlanAction (context, payload) {
    return new Promise((resolve, reject) => {
      context.commit("addInvoicePlanCommit", payload)
      resolve()
    })
  },
  addSelectedModuleForPlanAction (context, payload) {
    return new Promise((resolve, reject) => {
      context.commit("addSelectedModuleForPlanCommit", payload)
      resolve()
    })
  }
}
