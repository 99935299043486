/*eslint-disable */
/**
 * @copyright    Copyright © 2019-2025 MiniVentureLab
 * @author       Alok Pabalkar  <alok@miniventurelab.com>
 * @package      Gide
 * @license      Proprietary
 * @description  Console
 * 
 * API Request and Response Handler
 */

// import logout from './logout';
import axios from 'axios';
import store from '../store';

var request = {

/**
  * curl
  * Make a API Request
  * @param  
  */
 curl(context, api, post_data, api_domain=process.env.VUE_APP_GIDE_API){
  return new Promise((resolve, reject) => {
    var api_url = api_domain+"/"+api;
    
    let user_id_found = false;
    let acc_id_found = false;
    for (let pair of post_data.entries()) {  
      let key = pair[0];

      if(key === "user_id"){
        user_id_found = true;
      }

      if(key === "acc_id"){
        acc_id_found = true;
      }
    }

    if(!user_id_found){
      //If id not found then add
      if(store.getters['User/userData'] && store.getters['User/userData'].user_id){
        post_data.append('user_id', store.getters['User/userData'].user_id);
      }
      // console.log('user_id', store.getters.['User/userData'].user_id)
      //  post_data.append('user_id', 1);
    }
    
    if(!acc_id_found){
      //If id not found then add
      // post_data.append('acc_id', context.$store.getters.getAccId);
      if(store.getters['User/userData'] && store.getters['User/userData'].acc_id){
        post_data.append('acc_id', store.getters['User/userData'].acc_id);
      }
      else {
        post_data.append('acc_id', 1);
      }
    }
    
    post_data.append('user_tz', Intl.DateTimeFormat().resolvedOptions().timeZone);
    post_data.append('user_tz_offset', new Date().getTimezoneOffset());
    post_data.append('device_id', window.__DEVICE_OS__);

    axios({
      method: "post",
      url: api_url,
      timeout: 1000 * 60 * 5, // Wait for 60*5 seconds
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: 'Bearer ' + store.getters['User/getToken']
      },
      data: post_data
    })    
    .then((response) => {
      if(response.status === 200) {
        //if (process.env.VUE_APP_DEBUG == "true") {console.log("curl:Resp:", api, ":", response);}
        
        //If any request has a token than set it to vue store.
        if (response.data.token) {
          store.dispatch("User/setAuthTokenAction", response.data.token);
        }

        resolve(response.data);
      }
      else{
        if (process.env.VUE_APP_DEBUG == "true") {console.log("curl:API Error Resp:", api, ":", JSON.stringify(response.status));}  
        reject({resp_status:false, resp_code:response.status, resp_msg:"Error: Network Error"});  
      }
    })
    .catch((error) => {
      if (process.env.VUE_APP_DEBUG == "true") {console.log("curl:Exception:",JSON.stringify(error));}

      //If Request is not authorized
      if(error.response && error.response.status === 401){
        store.dispatch("User/setUserLogoutAction", null);
        localStorage.removeItem("user")
        localStorage.removeItem("access_token")
        context.$router.push({ name: "auth1.sign-in1" })
        reject(error.message);
      }

      if(error.message == "Request aborted"){
        //logout.logout(context);
        reject(error.message);
      }
      else if(error.code && error.code == "ECONNABORTED"){
        console.error(error);
        reject(error.message);
      }
      else if(error.message != undefined){
        //logout.logout(context);
        reject(error.message);
      }
      else{
        console.error(error.response);
        reject({resp_status:false, resp_code:error.message, resp_msg:"Network Error"});
      }
    })
  });  
}
}/*End of request Object */ 

export default request;
