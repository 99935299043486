/*eslint-disable*/
import store from "../store";
import { User } from '../FackApi/api/user'

export default class socket_listener {
  socket = null;
  store = null
  constructor(context, socket) {
    this.socket = socket;
    this.store = store

    //Socket on for userInvite for a new role
    this.socketUserInviteAdd(this);

    //Socket Message received when a messsage is added 
    this.socket_message_add_response(this);

    //Socket Message received when gpath report is generated
    this.socket_gpath_report_generated_listener(this);

    //Socket Message received when new notifications are available
    this.socket_new_notifications_available(this);

    this.socket_spcm_fireflies_add(this);
  }

  /**
   * maintenance_state_change_response listener from socket
   */
  socketUserInviteAdd(context) {
    /*Listen on Socket for socketUserInviteAdd*/
    this.socket.on('userInvite_add', async (state) => {
      console.log("Listen Received from Socket Message ==> userInvite_add=", state)
      let userData =  await store.getters['User/userData'];
      let userApiResp = await User.userView(context, userData.user_id, userData.user_id)
      if (userApiResp.resp_status) {
        let userDataResp = userApiResp.resp_data.data
        if (userApiResp.modules) {
          userDataResp.modules = userApiResp.modules
        }
        if (userApiResp.roles) {
          userDataResp.roles = userApiResp.roles
        }
        await this.store.dispatch('User/setUserProfileAction', userDataResp)
      }
    });
  }

  /**
   * socket_message_add_response
   */
   socket_message_add_response(context) {
    this.socket.on('message_add', async (data) => {
      console.log("Listen Received from Socket Message ==> message_add=", data)
    });
  }
  
  /**
   * gpath_report_generated_listener
   */
  socket_gpath_report_generated_listener(context) {
    // Listener for generation of report
    this.socket.on("gpath_report_generated", function(report_link) {
      console.log("Report Generated and link: ", report_link)

      let gpathSession = context.store.getters["GpathSession/gpathSession"]
      context.store.dispatch("GpathSession/setGpathSession", {...gpathSession, report: report_link, refreshValue: Math.random()})
    });
  }

  /**
   * new_notifications_available_listener
   */
  socket_new_notifications_available(context) {
    // Listener for generation of report
    this.socket.on("new_notifications_available", function(notificationObj) {
      let oldNotificationList = context.store.getters["Notification/notification"]
      if (!oldNotificationList) {
        oldNotificationList = []
      }
      else if (typeof oldNotificationList == "string") {
        oldNotificationList = JSON.parse(oldNotificationList)
      }

      let newNotificationObj = JSON.parse(notificationObj)
      
      let newNotificationList = [newNotificationObj, ...oldNotificationList];
      context.store.dispatch("Notification/setNotification", newNotificationList)
    });
  }

  /**
   * socket_spcm_fireflies_add
   */
  socket_spcm_fireflies_add(context) {
    // Listener fireflies add
    this.socket.on("spcm_fireflies_add", function(spcm_firefliesObj) {
      context.store.dispatch("SPCMFireflies/setSPCMFireflies", spcm_firefliesObj)
    });
  }  
}
