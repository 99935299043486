import getters from "./getters"
import actions from "./actions"
import mutations from "./mutations"
import cacheLs from "../cacheLs"

const state = {
  SPCMFireflies: cacheLs.get_SPCMFireflies()
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
