import cacheLs from "../cacheLs"

export default {
  setDeepLink (state, data) {
    state.selectedDeepLink = data
    cacheLs.set_deep_link(data)
  },
  deleteDeepLink (state, data) {
    state.selectedDeepLink = data
    cacheLs.delete_deep_link()
  }
}
