import Vue from "vue"
import moment from "moment"
import Utility from "../Utils/utility"

Vue.filter("reverse", function (value) {
  // slice to make a copy of array, then reverse the copy
  return value.slice().reverse()
})

Vue.filter("formatDate", function (value) {
  if (value) {
    if (typeof value === "number") {
      value = new Date(value * 1000)
    }
    return moment(String(value)).fromNow()
  }
})

Vue.filter("dateOnly", function (value) {
  if (value) {
    if (typeof value === "number") {
      value = new Date(value * 1000)
    }
    return moment(value).date()
  }
})

Vue.filter("monthOnly", function (value) {
  if (value) {
    if (typeof value === "number") {
      value = new Date(value * 1000)
    }
    return moment(value).format("MMM")
  }
})

Vue.filter("dateFormatMsg", function (value) {
  if (value) {
    if (typeof value === "number") {
      value = new Date(value * 1000)
    }

    if (moment().isSame(value, "day")) {
      // today
      return moment(value).local().format("hh:mm a")
    }
    else {
      return moment(value).local().format("DD-MM-YYYY hh:mm a")
    }
  }
})
Vue.filter("dateFormat", function (value) {
  if (value) {
    if (typeof value === "number") {
      value = new Date(value * 1000)
    }
    return moment(value).local().format("DD-MM-YYYY hh:mm a")
  }
})
Vue.filter("dateFormatMMYYYY", function (value) {
  if (value) {
    value = moment(value).format("MMM YYYY")
    return value
  }
  else {
    return value
  }
})
Vue.filter("dateFormatDDMMYYYY", function (value) {
  if (value) {
    if (typeof value === "number") {
      value = new Date(value * 1000)
    }
    value = moment(value).format("DD-MMM-YYYY")
    return value
  }
  else {
    return value
  }
})
Vue.filter("dateFormatDDMMYYYYSlash", function (value) {
  if (value) {
    if (typeof value === "number") {
      value = new Date(value * 1000)
    }
    value = moment(value).format("DD/MMM/YYYY")
    return value
  }
  else {
    return value
  }
})
Vue.filter("dateFormatMMDOYYYY", function (value) {
  if (value) {
    if (typeof value === "number") {
      value = new Date(value * 1000)
    }
    value = moment(value).format("MMM Do YYYY")
    return value
  }
  else {
    return value
  }
})
Vue.filter("dateFormatDDMMYYYYHHMMA", function (value) {
  if (value) {
    if (typeof value === "number") {
      value = new Date(value * 1000)
    }
    value = moment(value).format("DD MMM, YYYY hh:mm a")
    return value + ` (${Intl.DateTimeFormat().resolvedOptions().timeZone})`
  }
  else {
    return value
  }
})
Vue.filter("dateFormatDDMMMYYYYHHMMA", function (value) {
  if (value) {
    // if (typeof value === "number") {
    //   value = new Date(value * 1000)
    // }
    value = moment(value).format("DD MMM, YYYY hh:mm a")
    return value
  }
  else {
    return value
  }
})
Vue.filter("dateFormatDDMMYY", function (value) {
  if (value) {
    // if (typeof value === "number") {
    //   value = new Date(value * 1000)
    // }
    value = moment(value).format("DD MMM, YYYY")
    return value
  }
  else {
    return value
  }
})
Vue.filter("hhmmaa", function (value) {
  if (value) {
    // if (typeof value === "number") {
    //   value = new Date(value * 1000)
    // }
    value = moment(value).format("hh:mm a")
    return value
  }
  else {
    return value
  }
})
/*eslint-disable */
Vue.filter("addSuffixToClass", function (value) {
  if (value) {
    const suff = ["st", "nd", "rd"][ ((value + 90) % 100 - 10) % 10 - 1] || "th"
    return value + suff
  }
  else {
    return value
  }
})

Vue.filter("durationToDHM", function (seconds){
  const days =parseInt( seconds/ (24 * 3600));
 
  seconds= seconds% (24 * 3600);
  const hr = parseInt(seconds/ 3600);

  seconds%= 3600;
  const min = seconds/ 60;
  const sec = seconds%= 60;
  return `${days ? days.toFixed() + " Days" : ""} ${hr ? hr.toFixed() + " Hrs" : ""} ${min && min > 0.99 ? min.toFixed() + " Min" : ""} ${sec ? sec.toFixed() + " Sec" : ''}`
})

Vue.filter("durationLeft", function(date) {
  if (typeof date == 'number') {
    date = date * 1000
    date = new Date(date)
  }
  return Utility.getDiffDateTime(new Date(), date)
})

Vue.filter("is_empty", function(data) {
  if(!data) {
    return "-----"
  }
  
  return data
})
