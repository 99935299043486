import getters from "./getters"
import actions from "./actions"
import mutations from "./mutations"
import cacheLs from "../cacheLs"

const state = {
  selectedPlan: cacheLs.get_selectedPlan(),
  myPlan: cacheLs.get_MyPlan(),
  invoicePlan: cacheLs.get_InvoicePlan(),
  selectedModuleForPlan: cacheLs.get_SelectedModuleForPlan()
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
