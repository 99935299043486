export default {
  setAuthTokenAction (context, payload) {
    return new Promise((resolve, reject) => {
      context.commit("setAuthToken", payload)
      resolve()
    })
  },
  setUserProfileAction (context, payload) {
    return new Promise((resolve, reject) => {
      context.commit("setUserProfile", payload)
      resolve()
    })
  },
  setFcmPushTokenAction (context, payload) {
    return new Promise((resolve, reject) => {
      context.commit("setFcmPushToken", payload)
      resolve()
    })
  },
  setSelectedUserInterest (context, payload) {
    return new Promise((resolve, reject) => {
      context.commit("setSelectedUserInterest", payload)
      resolve()
    })
  },
  setAskedForUserInterest (context, payload) {
    return new Promise((resolve, reject) => {
      context.commit("setAskedForUserInterest", payload)
      resolve()
    })
  },
  setUserLogoutAction (context, payload) {
    return new Promise((resolve, reject) => {
      context.commit("setUserLogout", payload)
      resolve()
    })
  },
  refreshUserInterests (context, payload) {
    context.commit("refreshUserInterests", payload)
  }
}
