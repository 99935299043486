import getters from "./getters"
import actions from "./actions"
import mutations from "./mutations"
import cacheLs from "../cacheLs"

const state = {
  selectedTPrepSubjectModule: cacheLs.get_subject_module()
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
