import cacheLs from "../cacheLs"

export default {
  setUserSource (state, data) {
    state.selectedUserSource = data
    cacheLs.set_user_source(data)
  },
  deleteCode (state, data) {
    state.selectedUserSource = data
    cacheLs.delete_user_source()
  }
}
