import getters from "./getters"
import actions from "./actions"
import mutations from "./mutations"

/*eslint-disable */
import cacheLs from "../cacheLs";

cacheLs.CURRENT_USER_ID = cacheLs.get_current_user_id_ls();

const state = {
  token: cacheLs.get_auth_token_ls(cacheLs.CURRENT_USER_ID) || null,
  userData: cacheLs.get_user_data_ls(cacheLs.CURRENT_USER_ID) || null,
  fcm_push_token: cacheLs.getFcmPushToken_ls(cacheLs.CURRENT_USER_ID) || null,
  selectedUserInterests: cacheLs.get_selected_user_interests_ls() || null,
  askedForUserInterests: cacheLs.get_asked_for_user_interests_ls() || null,
  refreshUserInterests: 0 // This state will trigger the refresh of the user interests
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
