export default {
  SPCMFireflies (state) {
    if (typeof state.SPCMFireflies == "string") {
      return JSON.parse(state.SPCMFireflies)
    }
    else {
      return state.SPCMFireflies
    }
  }
}
