/*eslint-disable */
var iosBridge = {
  id: 1,
  handlers: {},
};

/*
 * iosBridge_receiveMessage
 * response_data => handle, error, data
 * data is base64 encoded so decode it
 * after decoding the data format is always a json.
 * Sample jsondata structure =>
 * 1. {"data": "123"}
 * 2. {"data": {"key1":"val1", "key2":"val2"}}
 */
window.iosBridge_receiveMessage = (response_data) => {
  response_data = response_data.split(",");
  let handle = response_data[0];
  let error = response_data[1] ? response_data[1] : false;
  let data = response_data[2];
  //Decode base64 data. this is encoded by iosBridge_receiveMessage
  var jsonString = atob(data);
  var jsondata = JSON.parse(jsonString);
  if (process.env.VUE_APP_DEBUG == "true") {console.log("iosBridge_receiveMessage", handle, error, jsondata);}

  //Don't reject as we never get error. This is just a formality to keep the implementation
  error = false;
  if (error && iosBridge.handlers[handle].reject) {
    iosBridge.handlers[handle].reject(jsondata);
  }
  else if (iosBridge.handlers[handle].resolve){
    iosBridge.handlers[handle].resolve(jsondata);
  }

  delete iosBridge.handlers[handle];
};

/*
 * iosBridge.sendMessage
 */
iosBridge.sendMessage = (data) => {
  return new Promise((resolve, reject) => {
    const handle = 'm' + iosBridge.id++;
    iosBridge.handlers[handle] = { resolve, reject };
    data["id"] = handle;
    if (process.env.VUE_APP_DEBUG == "true") {console.log("iosBridge.sendMessage", handle, data);}
    window.webkit.messageHandlers.GIDE_IOS_Bridge.postMessage(data);
  });
}

export default iosBridge;