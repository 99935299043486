export default {
  getPosts (state) {
    return state.posts
  },
  getPostCommentCount (state) {
    return state.postCommentCount
  },
  getPostNewImages (state) {
    return state.postAddImage
  }
}
